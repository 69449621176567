<template>
  <div class="box">
    <div class="l_nav">
      <ul>
        <li @click="goIndex"><a href="javascript:;">首页 </a></li>
        <li><span>/</span></li>
        <li>{{ params.name }}</li>
      </ul>
    </div>
    <div class="main">
      <template v-if="params.type == 1">
        <div class="section">
          <div class="leftBox">
            <h3 class="title"><span :style="{background:color}"></span>{{params.name}}</h3>
            <ul>
              <li v-for="(item,index) in leftList" :key="index" @click="clickNavigation(item,index)" :style="{background: active==index?color:'',color:active==index?'#fff':'#333'}">{{item.navigationName}}</li>
            </ul>
          </div>
          <div class="rightBox">
            <li v-for="(item,index) in list" :key="index" @click="goDetails(item.id)">
              <div class="name">{{item.journalisTitle}}</div>
              <div class="time">{{toDate(item.createTime,"yyyy-MM-dd")}}</div>
            </li>
            <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-size="query.size" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="content">
          <div class="title1">{{ details.journalisTitle }}</div>
          <div class="title_m">
            <span>信息来源：{{ details.message }}</span>
            <span>发布日期：{{ toDate(details.createTime, "yyyy-MM-dd") }}</span>
          </div>
          <div class="ql-editor" v-html="text"></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { selectNavigaionList,getTextarea } from "@/api/template";
import {getNavigationQueryId } from "../../../api/template";
export default {
  name: "List",
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      params: {},
      query: {
        current: 1,
        navigationId: "",
        navigationType: "",
        size: 15,
      },
      leftList:[],
      total: 0,
      dates: [],
      details: {},
      text: "",
      active:0,
      list:[]
    };
  },
  created() {
    // this.params = JSON.parse(window.sessionStorage.getItem("navigationValue"))
    this.params = this.$route.params
    this.getTwoNavigationName()
  },
  methods: {
    //获取二级子导航
    getTwoNavigationName(){
      getNavigationQueryId(this.params.id).then(res=>{
        if(res.code == 200){
          this.leftList = res.data.witem
          if (res.data.witem.length > 0) {
            this.query.navigationId = res.data.witem[0].id;
            this.query.navigationType = res.data.witem[0].navigationType;
          }else {
            this.query.navigationId = this.params.id;
            this.query.navigationType = this.params.type;
          }
          this.total = res.data.total;
          this.getSelectNavigaionList();
        }
      })
    },
    getTextareaFun(journalisContent) {
      let params = {
        journalisContent: journalisContent,
      };
      getTextarea(params).then((res) => {
        this.text = res.data.dataHtml
      });
    },
    clickNavigation(item,index){
      this.active = index
      this.query.navigationId = item.id;
      this.query.navigationType = item.navigationType;
      this.getSelectNavigaionList();
    },
    getSelectNavigaionList() {
      selectNavigaionList(this.query).then((res) => {
        if(res.code == 200){
          this.list = res.data.records;
          this.total = res.data.total;
          if(this.params.type == 2){
            this.details = res.data
            this.getTextareaFun(res.data.journalisContent)
          }
        }
      }).catch(() => {

      });
    },

    handleSizeChange(size) {
      this.query.size = size;
      this.init();
    },
    handleCurrentChange(current) {
      this.query.current = current;
      this.init();
    },
    goIndex() {
      this.$router.push({name:'template-eighth'});
    },
    goDetails(id) {
      let data = {
        name: this.params.name,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/eighth-details");
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  width: 1150px;
  min-height: 500px;
  margin: 30px auto;
  padding: 60px;
  background: rgba(243,243,243,.8);
  border: #ccc 1px solid;
  border-radius: 10px;
}
.l_nav {
  height: 40px;
  padding: 0 15px;
  background: #ddd;
  border-radius: 5px;
  ul {
    width: 1230px;
    margin: 0 auto;
    color: #999;
    li {
      float: left;
      line-height: 40px;
      span {
        padding: 0 10px;
      }
      a{
        color: #333;
      }
    }
  }
}
.main{
  .section{
    margin-top: 30px;
    .leftBox{
      display: inline-block;
      width: 160px;
      vertical-align: top;
      .title{
        height: 26px;
        line-height: 26px;
        margin-bottom: 30px;
        span{
          display: inline-block;
          width: 5px;
          height: 26px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      li{
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        cursor: pointer;
        overflow: hidden;
      }
    }
    .rightBox{
      position: relative;
      display: inline-block;
      width: 930px;
      min-height: 500px;
      background: #fff;
      border-radius: 5px;
      margin-left: 20px;
      padding: 20px;
      li{
        list-style-type: none;
        cursor: pointer;
        height: 28px;
        line-height: 28px;
        .name{
          float: left;
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .time{
          float: right;
          width: 20%;
          text-align: right;
        }
      }
      li:hover{
        color: green;
        //border-bottom: 1px solid  green;
      }
    }
  }
  .pagination{
    clear: both;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
.content {
  width: 1150px;
  min-height: 500px;
  margin: 30px auto 0;
  background: #fff;
  border-radius: 5px;

  .title1 {
    color: #1e3c5a;
    font-size: 26px;
    text-align: center;
    line-height: 80px;
    font-weight: bold;
  }

  .title_m {
    text-align: center;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 20px;
    //padding: 20px;
    font-size: 14px;

    span {
      margin-right: 30px;
    }
  }

  .ql-editor {
    padding: 20px;
    line-height: 30px;
  }
}
</style>
